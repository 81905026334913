import UserInfo from '../user-info/index.vue'
import MonthPicker from '../month-picker/index.vue'
import Rmb from '../../common/rmb'

export default {
  name: 'rebate-header',
  components: {
    UserInfo,
    MonthPicker,
    Rmb
  },
  model: {
    prop: 'selectedDate',
    event: 'onSelectedDateChange'
  },
  props: {
    userInfo: {
      required: true,
      type: Object,
      default: () => {
        return {
          userName: '吴亦凡',
          level: '股东',
          avatar: 'http://pics.sc.chinaz.com/files/pic/pic9/201509/apic14546.jpg'
        }
      }
    },
    minDate: {
      required: false,
      default: () => new Date(2020, 3) // 最小日期：2020-04
    },
    selectedDate: { //  当前日期选择器选中的时间
      required: true,
      default: () => new Date()
    }
  },
  methods: {
    changeSelectedDate (newVal) {
      this.$emit('onSelectedDateChange', newVal)
    }
  },
}
